import _layoutComponentsProps from '../_layoutComponentsProps'
import CalculatorTable from '../../components/CalculatorTable/CalculatorTable.vue'
import CalculatorSummary from '../../components/CalculatorSummary/CalculatorSummary.vue'
import CalculatorInfoBox from '../../components/CalculatorInfoBox/CalculatorInfoBox.vue'
import SendCalculation from '../../../../../../../Frontend/src/components/SendCalculation/SendCalculation.vue'

export default {
    name: 'NewLoanCalculatorResultDesktop',
    components: {
        CalculatorTable,
        CalculatorSummary,
        CalculatorInfoBox,
        SendCalculation,
    },
    props: _layoutComponentsProps,
    setup() {},
}
