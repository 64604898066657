import {computed} from "vue";
import { useStore } from "vuex";
import ChatFeedbackInput from "./ChatFeedbackInput/ChatFeedbackInput";
import ChatFeedbackPhoneNumber from "./ChatFeedbackPhoneNumber/ChatFeedbackPhoneNumber";
import ChatFeedbackReview from "./ChatFeedbackReview/ChatFeedbackReview";
export default {
  name: "ChatFeedback",
  components: {
    ChatFeedbackInput,
    ChatFeedbackPhoneNumber,
    ChatFeedbackReview
  },
  props: {},
  setup() {
    const store = useStore();

    const step = computed(()=> {
      return store.state.chatPanel.feedbackStep
    })

    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    async function sendConversationFeedback(payload) {
      const { feedback } = payload;
      if (feedback === "positive") {
        try {
          await store.dispatch("chatPanel/sendConversationFeedback", {
            feedback: {
              feedback: feedback,
            },
          });
        } catch (error) {
          throw new Error(error);
        } finally {
          await store.dispatch("chatPanel/resetChat");
        }
      } else if (feedback === "negative") {
        step.value = 'ChatFeedbackPhoneNumber'
      }
    }

    function setCurrentStep(inputStep) {
      store.commit("chatPanel/set_feedback_step", inputStep);
    }

    return {
      setCurrentStep,
      step,
      sendConversationFeedback,
      getLabel,
    };
  },
};
