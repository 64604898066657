import { computed } from 'vue'
import applyThousandSeperator from '../../js/applyThousandSeperator'

export default {
    name: 'CalculatorSummary',
    props: {
        data: {
            type: Object,
            required: true,
        },
        labels: {
            type: Object,
            required: true,
        },
        hasGraph: {
            type: Boolean,
            required: false,
            default: true,
        },
        onCallback: {
            type: Function,
            required: true,
            default: () => {},
        },
        hasHousingTypes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const mortgageLoanProcents = computed(() => {
            const total = props.data.bankLoanValue + props.data.mortgageLoanValue
            const procent = (props.data.mortgageLoanValue / total) * 100
            return procent.toFixed(2)
        })

        const cooperativeHousingLoanProcents = computed(() => {
            const total = props.data.bankLoanValue + props.data.mortgageLoanValue + props.data.cooperativeHousingLoanValue
            const procent = (props.data.cooperativeHousingLoanValue / total) * 100
            return procent.toFixed(2)
        })

        const removeTooltip = () => {
            document.querySelectorAll('.nyk-calculator-summary__svg-tooltip').forEach((tooltip) => tooltip.remove())
            document.removeEventListener('click', removeTooltip, true)
            document.removeEventListener('mouseleave', removeTooltipMouseLeave, true)
            document.removeEventListener('mousemove', showTooltipMouseOver, true)
        }

        const removeTooltipMouseLeave = () => {
            document.querySelectorAll('.nyk-calculator-summary__svg-tooltip').forEach((tooltip) => tooltip.remove())
            document.removeEventListener('mouseleave', removeTooltipMouseLeave, true)
            document.removeEventListener('mousemove', showTooltipMouseOver, true)
        }

        const buildTooltip = (e) => {
            const tooltipTitle = e.target.dataset.tooltipTitle
            const color = e.target.getAttribute('stroke')

            const mousePos = {
                x: e.clientX,
                y: e.clientY,
            }

            // build tooltip
            const tooltip = document.createElement('div')
            // style tooltip
            tooltip.classList.add('nyk-calculator-summary__svg-tooltip')
            tooltip.style.backgroundColor = color
            tooltip.style.top = `${window.scrollY + mousePos.y - 6}px`
            tooltip.style.left = `${mousePos.x}px`

            tooltip.innerHTML = tooltipTitle

            // append tooltip to body
            document.body.appendChild(tooltip)
        }

        const showTooltip = (e) => {
            removeTooltip(e)
            buildTooltip(e)
            document.addEventListener('click', removeTooltip, true)
        }

        const showTooltipMouseOver = (e) => {
            removeTooltipMouseLeave(e)
            buildTooltip(e)
            document.addEventListener('mouseleave', removeTooltipMouseLeave, true)
            document.addEventListener('mousemove', showTooltipMouseOver, true)
        }

        return {
            mortgageLoanProcents,
            cooperativeHousingLoanProcents,
            applyThousandSeperator,
            showTooltip,
            showTooltipMouseOver,
            removeTooltip,
        }
    },
}
