<script>
import options from './Modal.options'
export default options
</script>

<template>
    <dialog class="nyk-modal" :class="getModalStyles" ref="modalElementRef">
        <div class="nyk-modal__wrapper">
            <div class="nyk-modal__body">
                <div class="nyk-modal__body__header">
                    <div class="nyk-modal__body__title">{{ modalTitle }}</div>
                    <button
                        @click="closeModal"
                        autofocus
                        class="nyk-modal__body__close"
                        :aria-describedby="`${modalTitle}_title`"
                        :aria-label="componentProps?.labels?.modalCloseAriaLabel ?? 'Luk Modal'">
                        <svg role="presentation" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.5588 7.50011L7.49816 17.5608L6.4375 16.5001L16.4982 6.43945L17.5588 7.50011Z"
                                fill="#07094A" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.49816 6.43945L17.5588 16.5001L16.4982 17.5608L6.4375 7.50011L7.49816 6.43945Z"
                                fill="#07094A" />
                        </svg>
                    </button>
                </div>

                <div v-if="componentName" class="nyk-modal__body__content">
                    <component :is="componentName" v-bind="componentProps"></component>
                </div>

                <div v-if="htmlContent && !componentName" class="nyk-modal__body__content" v-html="htmlContent"></div>
            </div>

            <div v-if="isLoading" class="nyk-loader">
                <div class="nyk-loader__content">
                    <div class="nyk-loader__content__circle"></div>
                </div>
            </div>
        </div>
    </dialog>
</template>
