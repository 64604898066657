<script>
import options from "./SignupMultipleNewsletters.options"
export default options
</script>

<template>
  <div class="nyk-signup-multiple-newsletters">
    <div ref="stepAnnouncerRef" aria-live="assertive" aria-atomic="true" class="nyk-visually-hidden"></div>
    <component
      ref="currentStepComponentFocusRef"
      :is="currentStepName"
      :nextStepCallback="validateInputAndProceed"
      :previousStepCallback="returnToPreviousStep"
      :displayInputError="displayInputError"
      :currentStepTexts="currentStepTexts"
      :getNamedFieldInputData="getNamedFieldInputData"
      :newslettersData="newsletters"
      :hasPreviousStep="hasPreviousStep"
      :showFieldErrorMessage="showFieldErrorMessage"
      :resetSignupForm="resetSignupForm"
      :inputCallback="inputCallback"
      :stepCounterText="stepCounterText" />
  </div>
</template>
