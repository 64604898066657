import { nextTick, onMounted, ref, watch, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import ChatMenu from "../ChatMenu/ChatMenu"
import ChatFeedback from "../ChatFeedback/ChatFeedback"
import ChatConnectionError from "../ChatConnectionError/ChatConnectionError"
import { bodyScrollLock } from "foundation/js/utils/bodyScrollLock";

export default {
  name: "ChatWindow",
  components: {
    ChatMenu,
    ChatFeedback,
    ChatConnectionError
  },
  props: {
    initialMessage: {
      type: String,
      default: "",
    }
  },
  setup() {
    const store = useStore();
    const menuIsOpen = ref(false);
    const messageContainer = ref(undefined)
    const windowWidth = ref(undefined)

    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    function endConversation() {
      menuIsOpen.value = false
      if (store.state.chatPanel.showFeedback) {
        store.dispatch("chatPanel/resetChat");
      }
      else if (store.state.chatPanel.conversation?.id) {
        store.dispatch("chatPanel/showFeedback", true);
      }
      else {
        store.dispatch("chatPanel/resetChat");
      }
    }

    function minimizeChatWindow() {
      store.commit("chatPanel/set_chat_window_open", false);
    }

    function openMenu() {
      menuIsOpen.value = true;
    }

    function closeMenu() {
      menuIsOpen.value = false;
    }

    function navigateBack() {
      if (menuIsOpen.value) {
        menuIsOpen.value = false;
      }
      else if (store.state.chatPanel.showFeedback) {
        store.dispatch("chatPanel/showFeedback", false);
      }
    }

    const showFeedback = computed(() => {
      return store.state.chatPanel.showFeedback;
    })

    const showConnectionError = computed(() => {
      return store.state.chatPanel.connectionError
    })

    async function scrollToNewMessage() {
      if (messageContainer.value) {
        await nextTick();
        const lastMessage = messageContainer.value.lastElementChild;
        if (!lastMessage) return;
        messageContainer.value.scrollTo({
          top: lastMessage.offsetTop,
          behavior: 'smooth'
        });
      }
    }

    watch(() => store.state.chatPanel.messages, () => {
        scrollToNewMessage()
    });

    function applyBodyScrollLock(windowWidth) {
      const chatWindowOpen = store.state.chatPanel.chatWindowOpen;
      if (chatWindowOpen && windowWidth < 600) {
          bodyScrollLock.setOverflowHidden();
      }
      else {
        bodyScrollLock.clearOverflowHidden();
      }
    }

    watch(() => windowWidth.value, (newWidth) => {
      applyBodyScrollLock(newWidth)
    });

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    function addHandleResizeEventListener() {
      window.addEventListener('resize', handleResize);
      handleResize();
    }

    function resumeOrStartConversation() {
      const localStorageConversationId = sessionStorage.getItem('chatConversationId');
      if (localStorageConversationId) {
        store.dispatch("chatPanel/resumeConversation", localStorageConversationId)
      }
      else {
        store.dispatch("chatPanel/startConversation");
      }
    }

    onMounted(() => {
      addHandleResizeEventListener()
      resumeOrStartConversation()
    });

    return {
      openMenu,
      showConnectionError,
      navigateBack,
      closeMenu,
      getLabel,
      showFeedback,
      messageContainer,
      menuIsOpen,
      endConversation,
      minimizeChatWindow,
    };
  },
};
