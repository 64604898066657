import { onMounted, ref, defineExpose } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"

export default {
  name: "SignupStepReceipt",
  props: _signupStepsCommonProps,
  setup() {
    const contentClassnames = ref("nyk-signup-step__content--animation")

    // finishSignupButtonRef is used in the SignupSingleNewsletter and SignupMultipleNewsletters component
    const finishSignupButtonRef = ref(null)

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })

    return {
      finishSignupButtonRef,
      contentClassnames,
    }
  },
}
