import { useStore } from "vuex";
import { ref } from "vue";
import Loader from "components/Loader/Loader";
export default {
  name: "ChatFeedbackInput",
  components: {
    Loader
  },
  props: {
    setCurrentStep: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const isSendingFeedback = ref(false);
    const isClosing = ref(false)
    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    async function sendConversationFeedback(payload) {
      const { feedback } = payload;
      if (feedback === "positive") {
        try {
          isSendingFeedback.value = true;
          await store.dispatch("chatPanel/sendConversationFeedback", {
            feedback: {
              feedback: feedback,
            }
          });
          isSendingFeedback.value = false;
        } catch (error) {
          throw new Error(error);
        } finally {
          await store.dispatch("chatPanel/resetChat");
        }
      } else if (feedback === "negative") {
        props.setCurrentStep('ChatFeedbackPhoneNumber')
      }
    }

    async function closeFeedback() {
      isClosing.value = true
      await store.dispatch("chatPanel/endConversation");
      isClosing.value = false
    }

    return {
      isSendingFeedback,
      isClosing,
      closeFeedback,
      sendConversationFeedback,
      getLabel,
    };
  },
};
