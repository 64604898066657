import { createApp } from 'vue'
import Modal from './Modal'

const el = '.nyk-component--modal'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((ModalDOM) => {
            const component = createApp(Modal).use(Store)
            component.mount(ModalDOM)
        })
    }
}
