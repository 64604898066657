import { useStore } from "vuex";
import { onMounted, ref, nextTick } from "vue";
export default {
  name: "Loader",
  components: {},
  props: {
    classNames: {
      type: String,
      default: ''
    }
  },
  setup() {
  }
}