import { saveAs } from "foundation/js/file-download";

function findLastBotResponseId(messages) {
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].source === "bot") {
      return messages[i].id;
    }
  }
  return null;
}

function createMessageObject(textContent, id) {
  return {
    id: id,
    dateCreated: null,
    language: null,
    source: "client",
    elements: [
      {
        type: "text",
        payload: {
          html: null,
          text: textContent,
          links: null,
        },
      },
    ],
  };
}

function createStatusMessageObject(statusText) {
  return {
    ...createMessageObject("", "status_message"),
    source: "system",
    elements: [
      {
        type: "statusMessage",
        payload: {
          statusMessage: statusText,
        },
      },
    ],
  };
}

function createClientMessageId(state) {
  return state.latestResponseId
    ? (parseInt(state.latestResponseId) + 1).toString()
    : undefined;
}
export default {
  namespaced: true,
  state: {
    conversation: undefined,
    messages: [],
    chatWindowOpen: false,
    showFeedback: false,
    connectionError: false,
    labels: undefined,
    feedbackActionId: undefined,
    messageFeedback: [],
    latestResponseId: undefined,
    maxCharacters: undefined,
    isAwaitingResponse: false,
    finalMessage: undefined,
    feedbackStep: 'ChatFeedbackInput'
  },
  mutations: {
    set_is_awaiting_response(state, value) {
      state.isAwaitingResponse = value;
    },
    set_conversation(state, value) {
      state.conversation = value;
    },
    set_feedback_step(state,value) {
      state.feedbackStep = value
    },
    append_messages(state, messages) {
      const filteredNewList = messages.filter(message => message.source === "system" || parseInt(message.id) > parseInt(state.latestResponseId));
      let previousWasHumanAgent = false;
      let index = 0;

      while (index < messages.length) {
        const message = messages[index];

        if (message.is_human_agent && !previousWasHumanAgent) {
          const newMessage = createStatusMessageObject(state.labels["customerServiceJoined"], "");
          messages.splice(index, 0, newMessage);
          previousWasHumanAgent = true;
          index++; // Move to the next message after inserting
        } else if (!message.is_human_agent && previousWasHumanAgent) {
          const newMessage = createStatusMessageObject(state.labels["virtualAgentJoined"], "");
          messages.splice(index, 0, newMessage);
          previousWasHumanAgent = false;
          index++; // Move to the next message after inserting
        }

        index++; // Move to the next message in the list
      }
    
      if (state.messages && state.messages.length) {
        if (filteredNewList.length) {
          state.messages = [...state.messages, ...filteredNewList];
        }
      } else {
        state.messages = [...messages];
      }
    },
    set_chat_window_open(state, value) {
      state.chatWindowOpen = value;
    },
    set_labels(state, payload) {
      state.labels = payload;
    },
    set_feedback_action_id(state, value) {
      state.feedbackActionId = value;
    },
    set_show_feedback(state, value) {
      state.showFeedback = value;
    },
    set_message_feedback(state, payload) {
      const { id, value } = payload;
      const index = state.messageFeedback.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.messageFeedback[index].value = value;
      } else {
        state.messageFeedback.push({ id, value });
      }
    },
    set_connection_error(state, value) {
      state.connectionError = value;
    },
    set_latest_response_id(state, value) {
      state.latestResponseId = value;
    },
    setPollingInterval(state, intervalId) {
      state.pollingInterval = intervalId;
    },
    clearPollingInterval(state) {
      if (state.pollingInterval) {
        clearInterval(state.pollingInterval);
        state.pollingInterval = null;
      }
    },
    set_final_message(state, value) {
      state.finalMessage = value
    },
    reset_chat(state) {
      state.conversation = undefined;
      state.messages = [];
      state.showFeedback = false;
      state.connectionError = false;
      state.chatWindowOpen = false;
      state.messageFeedback = [];
      state.latestResponseId = undefined;
      state.maxCharacters = undefined;
      state.isAwaitingResponse = false;
      state.finalMessage = undefined;
      state.feedbackStep = 'ChatFeedbackInput'
      sessionStorage.removeItem("chatConversationId");
    },
  },
  actions: {
    async startConversation({ commit, dispatch }) {
      const request = new Request(process.env.CHATPANEL_START_CONVERSATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);

        if (!response.ok) {
          commit("set_connection_error", true);
          throw new Error("Failed to start conversation");
        }
        else {
          commit("set_connection_error", false);
        }

        const json = await response.json();
        dispatch("setConversationData", json);
        sessionStorage.setItem("chatConversationId", json.conversation.id);
        return true;
      } catch (err) {
        console.error("Error starting conversation:", err);
        throw new Error("Unable to start conversation: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    async resumeConversation({ commit, dispatch }, conversationId) {
      const url = `${process.env.CHATPANEL_RESUME_CONVERSATION}?conversation_id=${conversationId}`;

      const request = new Request(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);

        if (!response.ok) {
          commit("set_connection_error", true);
          throw new Error(
            "Failed to resume conversation: " + response.statusText
          );
        }
        else {
          commit("set_connection_error", false);
        }

        const json = await response.json();
        dispatch("setConversationData", json);
        return true;
      } catch (err) {
        console.error("Error starting conversation:", err);
        throw new Error("Unable to start conversation: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    setConversationData({ commit, dispatch }, json) {
      commit("set_conversation", json.conversation);
      dispatch("handleNewMessageData", json);
    },
    handleNewMessageData({ commit, dispatch, state }, json) {
      if (json.response) {
        commit("append_messages", [json.response]);
      } else if (json.responses && json.responses.length) {
        commit("append_messages", json.responses);
      }
      const lastestBotResponse = findLastBotResponseId(state.messages);
      if (lastestBotResponse !== null) {
        commit("set_latest_response_id", lastestBotResponse);
      }
      if (json.conversation.state.poll) {
        dispatch("startPolling");
      } else {
        dispatch("stopPolling");
      }
    },
    async endConversation({ state, dispatch }) {
      const url = `${process.env.CHATPANEL_END_CONVERSATION}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      try {
        const response = await fetch(request);

        if (!response.ok) {
          throw new Error("Failed to end conversation");
        }
        dispatch("resetChat");
        return true;
      } catch (err) {
        throw new Error("Unable to end conversation.: " + err);
      }
    },
    async sendActionLink({ commit, state, dispatch }, link) {
      const url = `${process.env.CHATPANEL_SEND_ACTION}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ actionId: link.id }),
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send action link");
        }
        dispatch("setConversationData", await response.json());
        return true;
      } catch (err) {
        throw new Error("Unable to send action link: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    async sendMessage({ state, commit, dispatch }, payload) {
      const url = `${process.env.CHATPANEL_SEND_MESSAGE}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: payload }),
      });
      try {
        const tempId = createClientMessageId(state);
        commit("append_messages", [createMessageObject(payload, tempId)]);
        commit("set_is_awaiting_response", true);
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send message");
        }
        const json = await response.json();
        dispatch("setConversationData", json);
        return true;
      } catch (err) {
        throw new Error("Unable to send message: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    async sendMessageFeedback({ state, commit }, payload) {
      const {feedback, id} = payload;
      const url = `${process.env.CHATPANEL_SEND_MESSAGE_FEEDBACK}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback, id }),
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send feedback");
        }
        commit("set_message_feedback", {
          id: payload.id,
          value: payload.feedback,
        });
        return true;
      } catch (err) {
        throw new Error("Unable to send feedback: " + err);
      }
    },
    async sendConversationFeedback({ state, commit }, payload) {
      const url = `${process.env.CHATPANEL_SEND_CONVERSATION_FEEDBACK}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload.feedback, feedbackActionId: state.feedbackActionId }),
      });

      try {
        const response = await fetch(request);
        const json = await response.json()
        commit("set_final_message", json)
        if (!response.ok) {
          throw new Error("Failed to send feedback");
        }
        return true;
      } catch (err) {
        throw new Error("Unable to send feedback: " + err);
      }
    },
    async deleteMessageHistory({ state, dispatch }) {
      const url = `${process.env.CHATPANEL_DELETE_MESSAGE_HISTORY}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to delete message history");
        }
        dispatch("resetChat");
        return true;
      } catch (err) {
        throw new Error("Unable to delete message history: " + err);
      }
    },
    async downloadConversation({ state, dispatch }) {
      const url = `${process.env.CHATPANEL_DOWNLOAD_MESSAGE_HISTORY}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to delete message history");
        }
        const blob = new Blob([await response.blob()], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, "Conversation.txt");
        return true;
      } catch (error) {
        throw new Error("Unable to download message history: " + error);
      }
    },
    async startPolling({ commit, state, dispatch }) {
      if (state.pollingInterval) {
        clearInterval(state.pollingInterval);
      }

      const intervalId = setInterval(async () => {
        try {
          const url = `${process.env.CHATPANEL_STATUS_POLLING_ENDPOINT}?conversation_id=${state.conversation.id}&latest_response_id=${state.latestResponseId}`;
          const request = new Request(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          const response = await fetch(request);
          dispatch("handleNewMessageData", await response.json());

          // TODO: We should also include a Boost.api error reponse here.
          if (!response.ok) {
            commit("set_connection_error", true);
          } else {
            commit("set_connection_error", false);
          }
        } catch (error) {
          throw new Error(error);
        }
      }, 3000);
      commit("setPollingInterval", intervalId);
    },
    stopPolling({ commit }) {
      commit("clearPollingInterval");
    },
    minimizeChatWindow({ commit }) {
      commit("set_chat_window_open", false);
    },
    showFeedback({ commit }, payload) {
      commit("set_show_feedback", payload);
    },
    resetChat({ commit, dispatch }) {
      dispatch("stopPolling");
      commit("reset_chat");
    },
    getLabel({ state }, label) {
      const returnLabel = state.labels[label];
      if (!returnLabel) {
        console.error("Label does not exist:", label);
        return `[${label}]`;
      }
      return returnLabel;
    },
  },
};
