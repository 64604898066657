import { createApp } from "vue"
import SignupSingleNewsletter from "./SignupSingleNewsletter"

const el = ".nyk-component--signup-single-newsletter"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SignupSingleNewsletterDOM) => {
      const labels = JSON.parse(SignupSingleNewsletterDOM.dataset.labels)
      const apiUrl = SignupSingleNewsletterDOM.dataset.apiUrl
      const enableUserTrack = SignupSingleNewsletterDOM.dataset.enableUserTrack ? JSON.parse(SignupSingleNewsletterDOM.dataset.enableUserTrack) : false
      const newsletters = SignupSingleNewsletterDOM.dataset.newsletters
        ? JSON.parse(SignupSingleNewsletterDOM.dataset.newsletters)
        : []

      const component = createApp(SignupSingleNewsletter, {
        labels,
        apiUrl,
        enableUserTrack,
        newsletters,
      }).use(Store)
      component.mount(SignupSingleNewsletterDOM)
    })
  }
}
