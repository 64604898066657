import { ref, computed } from 'vue'
import isBoolean from '../inputValidators/isBoolean'

export default function (userInput, stepsConfigAndOrder, labels) {
    const finalStepName = stepsConfigAndOrder.toReversed().name

    const getNamedFieldInputData = computed(() => {
        return userInput.reduce((dataObject, field) => {
            return Object.assign(dataObject, {
                [field.field]: field.value,
            })
        }, {})
    })

    const resetSignupForm = function () {
        stepsConfigAndOrder.forEach((step) => (step.completed = false))
        userInput.forEach((field) => {
            if (isBoolean(field.value)) {
                field.value = false
            } else {
                field.value = ''
            }
            field.isValid = false
        })
    }

    const currentStepName = computed(() => {
        return allStepsComplete.value ? finalStepName : stepsConfigAndOrder.find((stepData) => !stepData.completed).name
    })

    const currentStepNumber = computed(() => {
        return allStepsComplete.value ? finalStepName : stepsConfigAndOrder.find((stepData) => !stepData.completed).step
    })

    const currentStepTexts = computed(() => {
        return allStepsComplete.value ? finalStepName : stepsConfigAndOrder.find((stepData) => !stepData.completed).texts
    })

    const hasPreviousStep = computed(() => {
        return currentStepNumber.value > 1
    })

    const allStepsComplete = computed(() => {
        return !!(stepsConfigAndOrder.filter((stepData) => !stepData.completed).length === 0)
    })

    const countableSteps = stepsConfigAndOrder.filter((x) => x.isCountableStep).length

    const stepCounterText = computed(() => {
        const label = labels.stepLabel
        if (label) {
            return label
                .replace('{{currentStep}}', currentStepNumber.value)
                .replace('{{totalSteps}}', countableSteps.value || stepsConfigAndOrder.length)
        } else {
            return `${currentStepNumber.value} af ${countableSteps.value || stepsConfigAndOrder.length}`
        }
    })

    const stepCounterAccessibleText = computed(() => {
        const accessibleLabel = labels.accessibleStepLabel
        if (!accessibleLabel) {
            return accessibleLabel
                .replace('{{currentStep}}', currentStepNumber.value)
                .replace('{{totalSteps}}', countableSteps.value || stepsConfigAndOrder.length)
        } else {
            return `Du er nu på trin ${currentStepNumber.value} af ${countableSteps.value || stepsConfigAndOrder.length}`
        }
    })

    const showFieldErrorMessage = function (fieldName) {
        return !userInput.find((field) => field.field === fieldName).isValid
    }

    const returnToPreviousStep = function () {
        stepsConfigAndOrder.filter((step) => step.completed).toReversed()[0].completed = false
    }

    return {
        getNamedFieldInputData,
        resetSignupForm,
        currentStepName,
        currentStepTexts,
        hasPreviousStep,
        stepCounterText,
        stepCounterAccessibleText,
        showFieldErrorMessage,
        returnToPreviousStep,
    }
}
