import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CalculatorTable',
    props: {
        labels: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: false,
            default: [],
        },
        rows: {
            type: Array,
            required: false,
            default: [],
        },
        tooltip: {
            type: String,
            required: false,
        },
        collapse: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const store = useStore()
        const tableContainerRef = ref(null)
        const tableCollapsed = ref(false)
        const hiddensTableItemsShown = ref(false)

        const hiddenTableItems = computed(() => {
            if (props.rows.find((row) => row.hide)) {
                return true
            }

            return false
        })

        onMounted(() => {
            if (props.collapse) {
                handleAriaExpandedOnCollapsibleRow()
            }
        })

        const toggleTable = function (canCollapse) {
            if (canCollapse) {
                tableCollapsed.value = !tableCollapsed.value
                handleAriaExpandedOnCollapsibleRow()
            }
        }

        const handleAriaExpandedOnCollapsibleRow = function () {
            const firstRow = tableContainerRef.value.querySelector('tr')
            firstRow.setAttribute('aria-expanded', !tableCollapsed.value)
        }

        const toggleHiddenRows = function () {
            hiddensTableItemsShown.value = !hiddensTableItemsShown.value
        }

        const showTooltip = function (title) {
            store.dispatch('modal/setModalContent', {
                modalTitle: title,
                style: ['small', 'white'],
                htmlContent: props.tooltip,
            })
            store.dispatch('modal/setOpen', { open: true })
        }

        const tableStyles = computed(() => {
            let styles = ''

            if (props.collapse) {
                styles += 'nyk-calculator-table--collapse'
            }

            if (tableCollapsed.value) {
                styles += ' nyk-calculator-table--hidden'
            }

            if (hiddensTableItemsShown.value) {
                styles += ' nyk-calculator-table--show-hidden-row'
            }

            return styles
        })

        const getTableItemStyles = function (item) {
            let styles = ''

            if (item.styles) {
                styles += 'nyk-table__container__table__body__row__item--' + item.styles
            }

            return styles
        }

        return {
            tableContainerRef,
            tableCollapsed,
            hiddenTableItems,
            hiddensTableItemsShown,
            toggleTable,
            toggleHiddenRows,
            showTooltip,
            tableStyles,
            getTableItemStyles,
        }
    },
}
