import { registerGtmDataLayer } from '../../../../../Features/Blocks/Video/gtmDataLayerService';
export default async function (body, url) {
  let status = null
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      status = res.status
      if (res.ok) {
        return res.json()
      } else {
        throw new Error("Signup to get contacted didn't work")
      }
    })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error(err)
      return err
    })

    if (response && response.name && response.email && response.hash && response.tealiumEnvironment) {
      let dataLayer = {
          'event': 'track-immeo.newsletter',
          'tealium_visitor_id': `${response.tealiumEnvironment}_${response.hash}`,
          'payload': {
              'user_firstname': response.name,
              'user_lastname': response.lastname,
              'user_email': response.email,
              'user_phone': response.phonenumber,
              'user_hashed_email_firstname': response.hash,
              'user_hashed_email_firstname_is_present': 'true'
          }
      };

      registerGtmDataLayer(dataLayer);
  }
  return {
    response: response,
    status: status,
  }
}
