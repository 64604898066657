import { createStore } from 'vuex'

import main from './modules/store.main'
import menu from './modules/store.menu'
import search from './modules/store.search'
import jobs from './modules/store.jobs'
import chatPanel from './modules/store.chatPanel'
import modal from './modules/store.modal'

const store = createStore({
    modules: {
        main,
        menu,
        search,
        jobs,
        chatPanel,
        modal
    },
})

export default store
