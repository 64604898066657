import { createApp } from 'vue'
import NewLoanCalculatorInput from './NewLoanCalculatorInput'
import handleComponentProps from '../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--new-loan-calculator-input'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((NewLoanCalculatorInputDOM) => {
            const props = handleComponentProps(NewLoanCalculatorInput, NewLoanCalculatorInputDOM)
            const component = createApp(NewLoanCalculatorInput, props).use(Store)
            component.mount(NewLoanCalculatorInputDOM)
        })
    }
}
