<script>
import options from "./ChatMenu.options"
export default options
</script>

<template>
    <div class="nyk-chat-menu">
        <span class="nyk-chat-menu__heading">{{ getLabel("menuHeading") }}</span>
        <div class="nyk-chat-menu__menu">
            <button class="nyk-chat-menu__menu-item" @click="downloadConversation" :disabled="!conversationStarted">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="#07094A" stroke-linecap="round" stroke-linejoin="round" d="M13.8 11.644a3.518 3.518 0 0 0 1.51-1.88 3.542 3.542 0 0 0-.05-2.415 3.515 3.515 0 0 0-1.585-1.815 3.481 3.481 0 0 0-2.375-.36 5.545 5.545 0 0 0-1.245-2.291 5.499 5.499 0 0 0-2.15-1.462 5.468 5.468 0 0 0-5.01.61 5.522 5.522 0 0 0-1.74 1.938 5.56 5.56 0 0 0 1.554 7.083M11 12.148l-3 3.018-3-3.018M8 15.166V6.11"/></svg>
                <span>{{ getLabel("menuDownloadConversationButton") }}</span>
            </button>
            <button class="nyk-chat-menu__menu-item" @click="deleteMessages" :disabled="!conversationStarted">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18"><path stroke="#07094A" stroke-linecap="square" stroke-miterlimit="10" d="M14.818 6.933v8.781c0 .388-.153.76-.426 1.035a1.45 1.45 0 0 1-1.028.429H4.636a1.45 1.45 0 0 1-1.028-.43 1.468 1.468 0 0 1-.426-1.034V6.933M1 4.006h16M9 9.128v4.39M6.09 9.128v4.39M11.91 9.128v4.39"/><path stroke="#07094A" stroke-miterlimit="10" d="M6.09 4.005V1.078h5.819v2.927"/></svg>
                <span>{{ getLabel("menuDeleteConversationButton") }}</span>
            </button>
            <a class="nyk-chat-menu__menu-item" :href="privacyPolicyUrl">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 15"><path stroke="#07094A" stroke-linecap="square" stroke-miterlimit="10" d="M9.333.929h5.333v5.366M6 2.27H2.666a1.33 1.33 0 0 0-.942.392c-.25.252-.391.593-.391.95v9.39c0 .356.14.697.39.949s.59.393.943.393H12c.353 0 .692-.141.943-.393.25-.252.39-.593.39-.949V9.648"/></svg>
                <span>{{ getLabel("menuPrivacyPolicyButton") }}</span>
            </a>
        </div>
    </div>
</template>