import { computed } from "vue";
import { useStore } from "vuex"

export default {
    name: "ChatMessage",
    props: {
        message: {
            type: Object,
            required: true
        },
        isUser: {
            type: Boolean,
            required: true
        }
    },
    setup(props){
        const messageClassName = computed(() => {
            const sourceClassName = props.isUser ? "nyk-chat-message__content--user" : "nyk-chat-message__content--bot"
            const messageTypeClassName = props.message.type === "statusMessage" ? "nyk-chat-message__content--status-message" :
                props.message.type === "html" ? "nyk-chat-message__content--html" :
                props.message.type === "text" ? "nyk-chat-message__content--text" :
                props.message.type === "links" ? "nyk-chat-message__content--links" : ""
            return `${sourceClassName} ${messageTypeClassName}`
        })
        const store = useStore()

        function sendActionLink(link) {
            store.dispatch("chatPanel/sendActionLink", link)
        }

        function decodeUnicode(str) {
            return str.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
              return String.fromCharCode(parseInt(grp, 16));
            });
        }

        return {
            decodeUnicode,
            sendActionLink,
            messageClassName
        }
    }
}