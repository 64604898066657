<script>
import options from "./ChatFeedbackReview.options"
export default options
</script>

<template>
    <div class="nyk-chat-feedback-review">
        <span class="nyk-chat-feedback-review__final-message" v-html="finalMessageHtml()"></span>
        <div class="nyk-chat-feedback-review__review-message">
            <span class="nyk-chat-feedback-review__heading">{{ getLabel('reviewFinalMessageHeading') }}</span>
            <span class="nyk-chat-feedback-review__sub-heading" v-html="getLabel('reviewFinalMessageSubheadingHTML')"></span>
        </div>
    </div>
</template>