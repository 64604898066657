import { onUpdated, onMounted, onUnmounted, ref, defineExpose, watch } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../SignupStepActions/SignupStepActions.vue"

export default {
  name: "SignupStepEmail",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
  },
  setup(props) {
    const emailInput = ref(props.getNamedFieldInputData.email)
    const fontSizesDesktop = [56, 44, 32]
    const fontSizesMobile = [36, 24, 18]
    let resizeObserver = null

    // emailInputElement is used in the SignupSingleNewsletter and SignupMultipleNewsletters component
    const emailInputElement = ref(null)

    onMounted(() => {
      if(emailInputElement.value) {
        resizeObserver = new ResizeObserver(() => {
          adjustFontSize()
        })

        resizeObserver.observe(emailInputElement.value)
      }
    })

    watch(emailInput, (newEmailValue) => {
      if (props.inputCallback) {
        props.inputCallback(newEmailValue, 'email')
      }
    })

    onUpdated(() => {
      adjustFontSize()
    })

    onUnmounted(() => {
      if (resizeObserver && emailInputElement.value) {
        resizeObserver.unobserve(emailInputElement.value);
      }
    })

    const adjustFontSize = () => {
      if (emailInputElement.value) {
        const inputWidth = emailInputElement.value.offsetWidth
        const isMobileView = window.matchMedia('(max-width: 905px)').matches
        const fontSizes = isMobileView ? fontSizesMobile : fontSizesDesktop
        const resizePadding = Math.min(inputWidth * 0.1, 45)

        for (let i = 0; i < fontSizes.length; i++) {
          const fontSize = fontSizes[i] + 'px'
          const font = `${fontSize} ${window.getComputedStyle(emailInputElement.value).fontFamily}`
          let textWidth = calculateTextWidth(emailInput.value, font)

          if (textWidth <= inputWidth - resizePadding) {
            emailInputElement.value.style.fontSize = fontSize
            break;
          }
        }
      }
    }

    const calculateTextWidth = (text, font) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    }
    
    return {
      emailInput,
      emailInputElement,
    }
  },
}
