import { onMounted, ref, defineExpose, watch } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../SignupStepActions/SignupStepActions.vue"

export default {
  name: "SignupStepEmail",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
  },
  setup(props) {
    const firstnameInput = ref(props.getNamedFieldInputData.firstname)
    const lastnameInput = ref(props.getNamedFieldInputData.lastname)

    // firstnameInputRef is used in the SignupSingleNewsletter and SignupMultipleNewsletters component
    const firstnameInputRef = ref(null)

    watch(firstnameInput, (newFirstnameValue) => {
      if (props.inputCallback) {
        props.inputCallback(newFirstnameValue, 'firstname')
      }
    })

    watch(lastnameInput, (newLastnameValue) => {
      if (props.inputCallback) {
        props.inputCallback(newLastnameValue, 'lastname')
      }
    })

    return {
      firstnameInputRef,
      firstnameInput,
      lastnameInput,
    }
  },
}
