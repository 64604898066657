export default function (userInput, stepsConfigAndOrder, currentStepName, callback) {
    let displayInputError = false
  
    const stepInput = userInput.filter((stepData) => stepData.step === currentStepName)
    const stepInputComplete = stepInput.every((stepData) => stepData.isValid)
    const allStepInputComplete = userInput.every((input) => input.isValid)
  
    const advanceToNextStep = function () {
      stepsConfigAndOrder.find((stepData) => stepData.name === currentStepName).completed = true
      displayInputError = false
      if (allStepInputComplete) {
        callback(userInput)
      }
    }
    if (stepInputComplete) {
      advanceToNextStep()
    }
    else {
      displayInputError = true
    }
  
    return displayInputError
  }
  