import { registerGtmDataLayer } from '../../../../../Features/Blocks/Video/gtmDataLayerService';

export default async function (body, url) {
  const api = window.location.origin + "/" + url
  const response = await fetch(api, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
      .then((res) => {
        if (res.ok) {
            let dataLayer = {
                'event': 'track-event.nyhedsbrev_submit',
                'event_source': 'inline',
                'eventCategory': 'newsletter',
                'eventAction': 'submit',
                'eventLabel': body.newsletters
            };

            registerGtmDataLayer(dataLayer);

            const contentType = res.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                return res.json();
            } else {
                return res.text();
            }
      } else {
        throw new Error("Didn't work submitting the form signing up for one or more newsletters.")
      }
    })
    .then((json) => {
        return json
    })
    .catch((err) => {
      console.error(err)
      return err
    })

  if (response && response.name && response.email && response.hash && response.tealiumEnvironment) {
      let dataLayer = {
          'event': 'track-immeo.newsletter',
          'tealium_visitor_id': `${response.tealiumEnvironment}_${response.hash}`,
          'payload': {
              'user_firstname': response.name,
              'user_lastname': response.lastname,
              'user_email': response.email,
              'user_phone': response.phonenumber,
              'user_hashed_email_firstname': response.hash,
              'user_hashed_email_firstname_is_present': 'true'
          }
      };

      registerGtmDataLayer(dataLayer);
  }
  return response
}
