<script>
import options from "./ChatFeedback.options"
export default options
</script>

<template>
    <div class="nyk-chat-feedback">
        <ChatFeedbackInput :setCurrentStep="setCurrentStep" v-if="step === 'ChatFeedbackInput'"/>
        <ChatFeedbackPhoneNumber :setCurrentStep="setCurrentStep" v-if="step === 'ChatFeedbackPhoneNumber'" />
        <ChatFeedbackReview v-if="step === 'ChatFeedbackReview'"/>
    </div>
</template>