<script>
import options from "./ChatConnectionError.options"
export default options
</script>

<template>
    <div class="nyk-chat-connection-error">
        <div class="nyk-chat-connection-error__content">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 38"><path fill="#07094A" d="M22.628 38a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM12.73 24.101A13.903 13.903 0 0 1 22.627 20c.32 0 .637.025.954.046l3.478-3.478A17.884 17.884 0 0 0 9.9 21.272l-1.414 1.414 2.83 2.829 1.413-1.414ZM30.191 17.68l-3.07 3.07c2.012.68 3.86 1.806 5.406 3.352l1.414 1.415 2.83-2.83-1.416-1.413a17.974 17.974 0 0 0-5.164-3.594ZM33.331 10.297l2.995-2.995A29.856 29.856 0 0 0 22.628 4C14.614 4 7.081 7.121 1.415 12.787L0 14.201l2.829 2.829 1.414-1.415C9.154 10.705 15.683 8 22.628 8c3.753 0 7.382.796 10.703 2.297ZM39.008 8.862l-2.899 2.9a26.186 26.186 0 0 1 4.903 3.854l1.414 1.415 2.83-2.829-1.416-1.414a30.177 30.177 0 0 0-4.832-3.926Z"/><path fill="#07094A" d="M6.628 38a.999.999 0 0 1-.707-1.707l36-36a.999.999 0 1 1 1.414 1.414l-36 36a.997.997 0 0 1-.707.293Z"/></svg>
            <span class="nyk-chat-connection-error__description">Problemer med forbindelsen prøv igen</span>
        </div>
    </div>
</template>