import { ref, onMounted } from "vue"
import _signupStepsCommonProps from "../../foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepActions/SignupStepActions.vue"
import Tooltip from "../Tooltip/Tooltip.vue"

export default {
  name: "ContactStepNewsletters",
  components: {
    SignupStepActions,
    Tooltip,
  },
  props: _signupStepsCommonProps,
  setup(props) {
    const newsletterFields = ref([])
    const displayError = ref(false)
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      props.newslettersData.forEach((newsletter) => {
        newsletterFields.value.push({
            field: newsletter.displayname,
            salesforceMapping: newsletter.name,
          value: props.getNamedFieldInputData[newsletter.name],
          tooltip: newsletter.tooltip,
        })
      })

      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })

    const handleNextStep = () => {
      const anySelected = newsletterFields.value.some((field) => field.value)

      if (anySelected) {
          const array = []

          newsletterFields.value.forEach((field) => {
              array.push({
                  field: field.salesforceMapping,
                  name: field.field,
                  value: field.value,
              })
          })

          props.nextStepCallback(array)
      } else {
          props.nextStepCallback({ skipValidation: true });
      }
    }

    return {
      newsletterFields,
      handleNextStep,
      displayError,
      contentClassnames,
    }
  },
}
