<script>
import options from "./Loader.options"
export default options
</script>

<template>
    <div class="nyk-loader" :class="classNames">
        <div class="nyk-loader__content">
        <div class="nyk-loader__content__circle"></div>
        </div>
    </div>
</template>