/**
 * Types out given texts one character at a time into corresponding button's text content.
 * 
 * @async
 * @function typeLine
 * @param {HTMLElement[]} buttonTexts - Array of button elements whose text content will be updated.
 * @param {string[]} texts - Array of texts to be typed out.
 * @param {Object[]} indices - Array of objects containing the current index of the text being typed.
 * @returns {Promise<void>}
 */

import {nextTick} from 'vue';

async function typeLine(buttonTexts, texts, indices) {
    await nextTick();
    for (let i = 0; i < buttonTexts.length; i++) {
        if (!buttonTexts[i].value) continue;

        if (indices[i].current < texts[i].length) {
            buttonTexts[i].value.textContent += texts[i].charAt(indices[i].current);
            indices[i].current++;
            const progress = indices[i].current / texts[i].length;
            setTimeout(() => typeLine(buttonTexts, texts, indices), getEaseDelay(progress));
        }
    }
}

export default function startTypingAnimation(buttonTexts, texts) {
    const indices = texts.map(() => ({ current: 0 }));
    typeLine(buttonTexts, texts, indices);
}

function getEaseDelay(progress) {
    const minDelay = 50;
    const maxDelay = 100;
    return minDelay + (maxDelay - minDelay) * Math.pow(1 - progress, 2);
}