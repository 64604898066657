import { createApp } from 'vue'
import NewLoanCalculatorResult from './NewLoanCalculatorResult'
import handleComponentProps from '../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--new-loan-calculator-result'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((NewLoanCalculatorResultDOM) => {
            const props = handleComponentProps(NewLoanCalculatorResult, NewLoanCalculatorResultDOM)
            const component = createApp(NewLoanCalculatorResult, props).use(Store)
            component.mount(NewLoanCalculatorResultDOM)
        })
    }
}
