export const openSwitchLoanTypeModal = (store, props, title) => {
    store.dispatch('modal/setModalContent', {
        modalTitle: title,
        componentName: 'SwitchLoanTypeContent',
        componentProps: props,
        style: ['big'],
    })

    store.dispatch('modal/setOpen', { open: true })
}

export const openAdjustLoanModel = (store, props, title) => {
    store.dispatch('modal/setModalContent', {
        modalTitle: title,
        componentName: 'AdjustLoanContent',
        componentProps: props,
        style: ['big', 'white'],
    })

    store.dispatch('modal/setOpen', { open: true })
}
