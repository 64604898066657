import { ref } from "vue";
import { useStore } from "vuex";
import Loader from "components/Loader/Loader";

export default {
  name: "ChatFeedbackReview",
  components: {
    Loader
  },
  props: {},
  setup() {
    const store = useStore();
    const isClosing = ref(false)
    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    function finalMessageHtml() {
      if (
        store.state.chatPanel.finalMessage?.elements &&
        store.state.chatPanel.finalMessage.elements.length
      ) {
        return store.state.chatPanel.finalMessage?.elements[0].payload.html;
      }
    }

    async function closeFeedback() {
      isClosing.value = true
      try {
      await store.dispatch("chatPanel/endConversation");
      }
      finally {
        isClosing.value = false
      }
    }

    return {
      isClosing,
      closeFeedback,
      finalMessageHtml,
      getLabel,
    };
  },
};
