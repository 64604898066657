<script>
import options from './SignupStepReceipt.options'
export default options
</script>

<template>
    <section class="nyk-signup-step">
        <div class="nyk-signup-step__content" :class="contentClassnames">
            <div
                class="nyk-signup-step__content__section nyk-signup-step__content__section--full-width nyk-signup-step__content__section--space-between">
                <div class="nyk-signup-step__content__section nyk-signup-step__content__section--half-width">
                    <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
                </div>
                <div
                    class="nyk-signup-step__content__section nyk-signup-step__content__section--half-width nyk-signup-step__content__section--right">
                    <div class="nyk-signup-step__align-end">
                        <button
                            ref="finishSignupButtonRef"
                            v-on:click="resetSignupForm"
                            :aria-label="currentStepTexts.finishSignupLabel"
                            class="nyk-signup-step-receipt__close">
                            <span class="nyk-icon nyk-icon--ui-close"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="nyk-signup-step__receipt">
                <p class="nyk-signup-step__receipt__text">
                    {{ currentStepTexts.summary }}
                </p>
            </div>
        </div>
    </section>
</template>
