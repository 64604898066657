import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Modal from '../components/Modal/Modal.vue'
import mapNewLoanUrlParams from '../js/mapNewLoanUrlParams'
import { getTableHeaders, getTableRows } from './mapTableData'
import { openAdjustLoanModel, openSwitchLoanTypeModal } from './handleCalcModals'
import apiGetCalculatorData from './apiGetCalculatorData'
import NewLoanCalculatorResultDesktop from './NewLoanCalculatorResultDesktop/NewLoanCalculatorResultDesktop.vue'
import NewLoanCalculatorResultMobile from './NewLoanCalculatorResultMobile/NewLoanCalculatorResultMobile.vue'

export default {
    name: 'NewLoanCalculatorResult',
    components: {
        NewLoanCalculatorResultDesktop,
        NewLoanCalculatorResultMobile,
        Modal,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        apiUrl: {
            type: String,
            required: true,
        },
        blockId: {
            type: String,
            required: true,
        },
        tableGroups: {
            type: Array,
            required: true,
            default: [],
        },
        housingTypes: {
            type: Array,
            required: true,
            default: [],
        },
        initData: {
            type: Object,
            required: true,
        },
        summary: {
            type: Object,
            required: true,
        },
        infoBox: {
            type: Object,
            required: true,
        },
        sendCalculation: {
            type: Object,
            required: false,
        },
    },
    setup(props) {
        const store = useStore()

        // DATA FOR RENDERING
        const infoBox = ref([{ ...props.infoBox }])
        const summaries = ref([{ ...props.summary }])
        const tables = ref([{ ...props.tableGroups }])
        const calculationData = ref([{ ...props.initData }])
        const showSendCalculation = ref(false)
        const isLoading = ref(false)

        const modalOpen = computed(() => store.state.modal.open)

        const getLoanTypesForSummary = (index) => {
            const currentType = props.housingTypes.find((item) => item.keyName === calculationData.value[index].housingType)
            return currentType?.loanTypes ?? []
        }

        watch(isLoading, (value) => {
            store.dispatch('modal/setIsLoading', { isLoading: value })
        })

        // SET CALCULATION DATA
        const setCalculationData = (data, index) => {
            if (data.loanType) calculationData.value[index].loanType = data.loanType
            if (data.housePrice) calculationData.value[index].housePrice = parseFloat(data.housePrice)
            if (data.ownPayment) calculationData.value[index].ownPayment = parseFloat(data.ownPayment)
            if (data.housingType) calculationData.value[index].housingType = data.housingType
            if (data.mortgageLoanInterestOnlyLength)
                calculationData.value[index].mortgageLoanInterestOnlyLength = parseFloat(data.mortgageLoanInterestOnlyLength)
            if (data.mortgageLoanTenureLength)
                calculationData.value[index].mortgageLoanTenureLength = parseFloat(data.mortgageLoanTenureLength)
            if (data.bankLoanTenureLength) calculationData.value[index].bankLoanTenureLength = parseFloat(data.bankLoanTenureLength)
        }

        const setResponseData = (response, index) => {
            if (index !== null) {
                summaries.value[index] = { ...response.summary }
                tables.value[index] = { ...response.tableGroups }
                calculationData.value[index] = { ...response.initData }
                infoBox.value[index] = { ...response.infoBox }
            } else {
                summaries.value.push({ ...response.summary })
                tables.value.push({ ...response.tableGroups })
                calculationData.value.push({ ...response.initData })
                infoBox.value.push({ ...response.infoBox })
            }
        }

        const redirect = () => {
            const rootPath = window.location.href.replace(window.location.search, '')
            const params = mapNewLoanUrlParams({
                housePrice: calculationData.value[0].housePrice,
                ownPayment: calculationData.value[0].ownPayment,
                housingType: calculationData.value[0].housingType,
                mortgageLoanInterestOnlyLength: calculationData.value[0].mortgageLoanInterestOnlyLength,
                mortgageLoanTenureLength: calculationData.value[0].mortgageLoanTenureLength,
                bankLoanTenureLength: calculationData.value[0].bankLoanTenureLength,
                productType: calculationData.value[0].loanType,
            })
            window.location.href = rootPath + params
        }

        // HANDLE MODALS
        // SwitchLoanTypeContent
        const switchLoanTypeProps = (index, callback) => ({
            currentHousingType: getLoanTypesForSummary(index),
            labels: props.labels,
            index: index,
            selectedLoanType: calculationData.value[index].loanType,
            onCallback: callback,
        })

        const switchLoanType = function (index) {
            const handleCallback = async function (loanType, currentIndex) {
                setCalculationData({ loanType }, currentIndex)

                const loadingTimeout = setTimeout(() => {
                    isLoading.value = true
                }, 100)

                const response = await apiGetCalculatorData(calculationData.value[currentIndex], props.apiUrl, props.blockId)
                setResponseData(response, currentIndex)

                store.dispatch('modal/setOpen', { open: false })

                clearTimeout(loadingTimeout)
                isLoading.value = false
            }

            openSwitchLoanTypeModal(store, switchLoanTypeProps(index, handleCallback), props.labels.loanTypesTitle)
        }

        const switchLoanTypeWithRedirect = function (index) {
            const handleCallback = function (loanType, currentIndex) {
                setCalculationData({ loanType }, currentIndex)

                const loadingTimeout = setTimeout(() => {
                    isLoading.value = true
                }, 100)
                setTimeout(() => redirect(), 50)
            }

            openSwitchLoanTypeModal(store, switchLoanTypeProps(index, handleCallback), props.labels.loanTypesTitle)
        }

        // AdjustLoanContent
        const adjustLoanProps = (index, callback) => ({
            labels: props.labels,
            housingTypes: props.housingTypes,
            showHousingTypes: calculationData.value.length === 1,
            index: index,
            initData: calculationData.value[index],
            onCallback: callback,
        })

        const adjustLoan = function (index) {
            const handleCallback = async function (data, currentIndex) {
                setCalculationData(data, currentIndex)

                const loadingTimeout = setTimeout(() => {
                    isLoading.value = true
                }, 100)

                const response = await apiGetCalculatorData(calculationData.value[currentIndex], props.apiUrl, props.blockId)

                setResponseData(response, currentIndex)

                store.dispatch('modal/setOpen', { open: false })

                clearTimeout(loadingTimeout)
                isLoading.value = false
            }

            openAdjustLoanModel(store, adjustLoanProps(index, handleCallback), props.labels.adjustLoanModalTitle)
        }

        const adjustLoanWithRedirect = function (index) {
            const handleCallback = function (data) {
                setCalculationData(data, 0)
                const loadingTimeout = setTimeout(() => {
                    isLoading.value = true
                }, 100)
                setTimeout(() => redirect(), 50)
            }

            openAdjustLoanModel(store, adjustLoanProps(index, handleCallback), props.labels.adjustLoanModalTitle)
        }

        const openCompareLoanModal = async function (e) {
            e.target.disabled = true

            const clone = { ...calculationData.value[0] }
            const houseType = props.housingTypes.find((item) => item.keyName === clone.housingType)
            const loanType = houseType?.loanTypes.find((item) => item.type === clone.loanType)

            clone.loanType = clone.loanType = loanType?.defaultCompareLoanType ?? props.initData?.defaultCompareLoanType
            clone.mortgageLoanInterestOnlyLength = houseType?.defaultMortgageLoanInterestOnlyLength ?? 0
            clone.mortgageLoanTenureLength = houseType?.defaultMortgageLoanTenureLength ?? 0
            clone.bankLoanTenureLength = houseType?.defaultBankLoanTenureLength ?? 0

            const loadingTimeout = setTimeout(() => {
                isLoading.value = true
            }, 100)

            const response = await apiGetCalculatorData(clone, props.apiUrl, props.blockId)
            setResponseData(response, null)

            clearTimeout(loadingTimeout)
            isLoading.value = false

            setTimeout(() => {
                e.target.disabled = false
            }, 500)

            window.scrollTo(0, 0)
        }

        const quitCompareMode = function () {
            summaries.value.pop()
            tables.value.pop()
            calculationData.value.pop()
        }

        const openSendCalculationsModal = function () {
            showSendCalculation.value = !showSendCalculation.value

            if (showSendCalculation.value) {
                setTimeout(() => document.querySelector('.nyk-js--send-calc-close-button')?.focus(), 300)
            }
        }

        const closeSendCalculationModal = () => {
            showSendCalculation.value = false
            document.querySelector('.nyk-js--send-calc')?.focus()
        }

        return {
            infoBox,
            summaries,
            tables,
            showSendCalculation,
            isLoading,
            modalOpen,
            getLoanTypesForSummary,
            getTableHeaders,
            getTableRows,
            switchLoanType,
            switchLoanTypeWithRedirect,
            openSendCalculationsModal,
            openCompareLoanModal,
            adjustLoan,
            adjustLoanWithRedirect,
            quitCompareMode,
            closeSendCalculationModal,
        }
    },
}
