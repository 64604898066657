import {createApp} from 'vue'
import NewLoanCalculatorResultMobile from './NewLoanCalculatorResultMobile'

const el = '.nyk-component--new-loan-calculator-result-mobile'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((NewLoanCalculatorResultMobileDOM)=>{
            const component = createApp(NewLoanCalculatorResultMobile).use(Store)
            component.mount(NewLoanCalculatorResultMobileDOM)
        })
    }
}