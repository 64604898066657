<script>
import options from "./SignupStepFullname.options"
export default options
</script>

<template>
    <section class="nyk-signup-step nyk-signup-step-fullname">
        <div class="nyk-signup-step__content">
            <div class="nyk-signup-step__content__section nyk-signup-step__content__section--full-width">
                <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
                <p class="nyk-signup-step__stepcounter--mobile">{{ stepCounterText }}</p>
                <div class="nyk-signup-step__summary" v-html="currentStepTexts.summary"></div>
            </div>
            <div class="nyk-signup-step__mt-auto nyk-signup-step__content__section nyk-signup-step__content__section--full-width">
                <div aria-live="polite" class="nyk-signup-step__inputs">
                    <div class="nyk-signup-step__general-input">
                        <label class="nyk-visually-hidden" for="nyk-signup-step__input-firstname">{{ currentStepTexts.firstNameLabel }}</label>
                        <input
                            id="nyk-signup-step__input-firstname"
                            ref="firstnameInputRef"
                            class="nyk-signup-step__input"
                            :placeholder="currentStepTexts.firstNamePlaceholder"
                            v-model="firstnameInput"
                            autocomplete="given-name"
                            v-on:keydown.enter="
                                nextStepCallback([
                                    { field: 'firstname', value: firstnameInput },
                                    { field: 'lastname', value: lastnameInput },
                                ])
                            " />

                        <p class="nyk-signup-step__input-error-msg">
                            <template v-if="displayInputError && showFieldErrorMessage('firstname')">
                                Indtast venligst et fornavn <span class="nyk-icon nyk-icon--ui-form-error--primary"></span
                            ></template>
                        </p>
                    </div>

                    <div class="nyk-signup-step__general-input">
                        <label class="nyk-visually-hidden" for="nyk-signup-step__input-lastname">{{currentStepTexts.lastNameLabel}}</label>
                        <input
                            id="nyk-signup-step__input-lastname"
                            class="nyk-signup-step__input"
                            :placeholder="currentStepTexts.lastNamePlaceholder"
                            autocomplete="family-name"
                            v-model="lastnameInput"
                            v-on:keydown.enter="
                                nextStepCallback([
                                    { field: 'firstname', value: firstnameInput },
                                    { field: 'lastname', value: lastnameInput },
                                ])
                            " />

                        <p class="nyk-signup-step__input-error-msg">
                            <template v-if="displayInputError && showFieldErrorMessage('lastname')">
                                Indtast venligst et efternavn <span class="nyk-icon nyk-icon--ui-form-error--primary"></span
                            ></template>
                        </p>
                    </div>
                </div>
                <SignupStepActions
                    :previousStepCallback="previousStepCallback"
                    :nextStepCallback="
                        () =>
                            nextStepCallback([
                                { field: 'firstname', value: firstnameInput },
                                { field: 'lastname', value: lastnameInput },
                            ])
                    "
                    :nextStepButtonText="currentStepTexts.finalize"
                    :stepCounterText="stepCounterText"
                    :hasPreviousStep="hasPreviousStep" />
            </div>
        </div>
    </section>
</template>
