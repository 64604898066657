import { onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CalculatorJuraContent',
    props: {
        juraModal: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()

        // handle Jura modal
        onMounted(() => {
            store.dispatch('modal/setHashObject', {
                id: props.juraModal.anchorId,
                object: props.juraModal,
            })

            const hash = window.location.hash.replace('#', '')
            if (hash === props.juraModal.anchorId) {
                setTimeout(() => {
                    store.dispatch('modal/setModalContent', {
                        modalTitle: props.juraModal.title,
                        htmlContent: props.juraModal.description,
                        style: ['small-long', 'white'],
                    })
                    store.dispatch('modal/setOpen', { open: true })
                }, 50)
            }
        })
    },
}
