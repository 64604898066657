<script>
import options from './NewLoanCalculatorInput.options'
export default options
</script>

<template>
    <article class="nyk-new-loan-calculator-input">
        <div v-if="systemErrorMessage && !hideSystemErrorMessage" class="nyk-new-loan-calculator-input__banner">
            <article class="nyk-context-banner">
                <div class="nyk-context-banner__content">
                    <div class="nyk-context-banner__content__info">
                        <p class="nyk-context-banner__content__info__description">
                            {{ systemErrorMessage }}
                        </p>
                    </div>
                </div>
                <button class="nyk-context-banner__link" v-on:click="handleHideSystemErrorMessage"></button>
            </article>
        </div>
        <div class="nyk-new-loan-calculator-input__content-wrapper">
            <div class="nyk-new-loan-calculator-input__content">
                <h2 class="nyk-new-loan-calculator-input__content-title">{{ labels.title }}</h2>

                <NewLoanCalculatorInputs
                    :labels="labels"
                    :housingTypes="housingTypes"
                    :initData="initData"
                    :displayErrors="displayErrors"
                    :onChangeCallback="onChangeCallback" />

                <div class="nyk-new-loan-calculator-input__content-actions">
                    <button class="nyk-button nyk-button--filled-primary" v-on:click="submit">{{ labels.buttonLabel }}</button>
                    <a :href="`#${juraModal.anchorId}`" class="nyk-button nyk-button--link">
                        {{ juraModal.title }}
                        <div class="nyk-button__icon">
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                <path
                                    fill="#07094A"
                                    d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div v-if="isLoading" class="nyk-loader">
            <div class="nyk-loader__content">
                <div class="nyk-loader__content__circle"></div>
            </div>
        </div>
    </article>

    <Modal />
</template>
