import { createApp } from 'vue'
import CalculatorJuraContent from './CalculatorJuraContent'
import handleComponentProps from '../../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--calculator-jura-content'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((CalculatorJuraContentDOM) => {
            const props = handleComponentProps(CalculatorJuraContent, CalculatorJuraContentDOM)
            const component = createApp(CalculatorJuraContent, props).use(Store)
            component.mount(CalculatorJuraContentDOM)
        })
    }
}
