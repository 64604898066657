<script>
import options from "./ChatWindow.options"
export default options
</script>

<template>
    <div class="nyk-chat-window">
        <div class="nyk-chat-window__header">
            <template v-if="!menuIsOpen && !showFeedback">
                <button @click="openMenu" title="Menu" class="nyk-chat-window__header-menu-icon-burger">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                        <path fill="#07094A" fill-rule="evenodd"
                            d="M0 0h16v1.78H0V0Zm0 7.11h10v1.78H0V7.11Zm16 7.109H0v1.78h16v-1.78Z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </template>
            <template v-else>
                <button @click="navigateBack" v-if="!showFeedback" :title="getLabel('topMenuNavigateBackButtonTitle')"
                    class="nyk-chat-window__header-menu-icon-back">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 11"><path stroke="#212226" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m5 1.272-4 4 4 4"/></svg>
                </button>
            </template>
            <div class="nyk-chat-window__header-action-buttons">
                    <button @click="minimizeChatWindow" :title="getLabel('topMenuMinimizeButtonTitle')" class="nyk-chat-window__header-action-minimize nyk-button nyk-button--link-icon">
                        <svg  class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                            <path fill="#07094A" fill-rule="evenodd"
                                d="m1.282 0 5.436 5.437L12.156 0l1.281 1.282L6.718 8 0 1.282 1.282 0Z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <button @click="endConversation" :title="getLabel('topMenuCloseButtonTitle')" class="nyk-chat-window__header-action-close nyk-button nyk-button--link-icon nyk-button--link-circle">
                        <svg class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path fill="#07094A" fill-rule="evenodd"
                                d="M17.563 7.5 7.502 17.56l-1.06-1.06 10.06-10.06 1.06 1.06Z" clip-rule="evenodd" />
                            <path fill="#07094A" fill-rule="evenodd"
                                d="m7.502 6.44 10.06 10.06-1.06 1.06L6.442 7.5l1.06-1.06Z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
        </div>
        <div class="nyk-chat-window__content">
            <div ref="messageContainer" class="nyk-chat-window__message-container">
                <div v-if="initialMessage" class="nyk-chat-window__initial-message" v-html="initialMessage"></div>
                <slot name="messages"></slot>
                <slot name="loading-messages"></slot>
            </div>
            <slot name="input-message"></slot>
            <ChatFeedback v-if="showFeedback"></ChatFeedback>
            <ChatConnectionError v-if="showConnectionError"></ChatConnectionError>
            <transition-group name="menuAppear" appear>
                <ChatMenu v-if="menuIsOpen"></ChatMenu>
            </transition-group>
        </div>
    </div>
</template>